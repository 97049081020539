import { GetStaticPaths, GetStaticProps } from 'next'
import ContentPage from '@/screens/ContentPage'
import { GetContentPageDocument } from '@/queries/queries/getContentPage.generated'
import { defaultGetStaticPathsFallback, defaultGetStaticProps, getStaticPathForPages } from '@/utils/pageUtils'

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: getStaticPathForPages(['naval']).paths,
    fallback: defaultGetStaticPathsFallback,
  }
}

export const getStaticProps: GetStaticProps = async (context) =>
  defaultGetStaticProps('companies', context, { query: GetContentPageDocument }, (data) => data.contentPage == null)

export default ContentPage
